import React from 'react'
import { css } from '@emotion/react'

interface Props {
	children: React.ReactNode
	printRef: React.Ref<HTMLDivElement>
}

function Print({ children, printRef }: Props) {
	return (
		<div css={Container} ref={printRef}>
			{children}
		</div>
	)
}

export default Print

const Container = css`
	position: fixed;
`
